const initialState = {
  stages: [],
  pipelines: [],
  selectedDemand: null
}

const DemandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STAGES':
      return { ...state, stages: action.stages }
    case 'GET_PIPELINES':
        return { ...state, pipelines: action.pipelines }  
    case 'GET_FILES':
      return { ...state, files: action.files }  
    case 'GET_PROCESS_RECORDS':
      return { ...state, records: action.records} 
    case 'GET_PROCESS_OBS':
      return { ...state, obs: action.obs }  
    case 'GET_PROCESS_BY_ID':
      return { ...state, selectedDemand: action.selectedDemand }
    case 'SET_SELECTED_DEMAND':
      return { ...state, selectedDemand: action.payload }    
    case 'GET_DEMANDS':
      const isPagination = action.isPagination || false // Verifica se a ação é de paginação
      const key = action?.stageId || "processesData"

      if (isPagination) {
        const totalValue = [...state[action.stageId]?.processes || [], ...action.data || []].reduce((acc, process) => {
          return acc + Number(process?.netPremium)
        }, 0)

        return {
          ...state,
          [key]: {
            stageId: action.stageId,
            stageTypes: action.stageTypes,
            processes: [...state[action.stageId]?.processes || [], ...action.data || []],
            count: action.count || 0,
            offset: action.offset || 0,
            totalValue
          }
        }
      } else {
        const totalValue = action.data?.reduce((acc, process) => {
          return acc + Number(process?.netPremium)
        }, 0)

        return {
          ...state,
          [key]: {
            stageId: action.stageId,
            stageTypes: action.stageTypes,
            processes: action.data || [],
            count: action.count || 0,
            offset: action.offset || 0,
            totalValue
          }
        }
      }
    default:
      return state
  }
}

export default DemandsReducer