// ** Initial State
const initialState = {
  leads: [],
  selectedTask: {},
  params: {
    filter: '',
    q: '',
    sort: '',
    tag: ''
  },
  brokers: [],
  users: [],
  clients: [],
  policies: [],
  clientLimits: {
    cliente: null,
    limits: {
      sombrero: [],
      pottencial: []
    }
  },
  limitsCount: {
    sombrero: [],
    pottencial: []
  }
}

const LeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LEADS':
      return { ...state, leads: action.leads, params: action.params }
    case 'GET_LEAD_BY_ID':
      return { ...state, leadById: action.lead }  
    case 'GET_CONTACT':
      return { ...state, contact: action.contacts, params: action.params }
    case 'GET_NUMBER_CONTACTS':
      return { ...state, numberContacts: action.numberContacts, params: action.params }
    case 'UPDATE_TASKS':
      return { ...state }
    case 'REORDER_TASKS':
      return { ...state, leads: action.leads }
    case 'SELECT_TASK':
      return { ...state, selectedLead: action.leads }
    case 'GET_TAGS':
      return { ...state, tags: action.tags }
    case 'GET_BROKERS':
      return { ...state, brokers: action.brokers }
    case 'GET_CLIENTS':
      return { ...state, clients: action.clients }
    case 'GET_USERS':
      return { ...state, users: action.users }
    case  'GET_STATES':
      return { ...state, states: action.states }
    case 'UPDATE_USERS':
      return { ...state }
    case 'DELETE_USER':
      return { ...state } 
    case 'GET_POLICY':
      return { ...state, policy: action.policy, policyCount: action.policyCount }
    case 'GET_POLICIES':
      return { ...state, policies: [...state.policies, ...action.policies] }  
    case 'GET_LIMITS_COUNT':
      const insurance1 = action.insurance?.toLowerCase()
      return { ...state, limitsCount: { ...state.limitsCount, [insurance1]: [...state.limitsCount[insurance1], ...action.count]}}  
    case 'GET_LIMITS':
      const insurance2 = action.insurance?.toLowerCase()
      return {
        ...state,
        clientLimits: {
          ...state.clientLimits,
          cliente: action.cliente,
          limits: {
            ...state.clientLimits.limits,
            [insurance2]: action.limits
          }
        }
      }
    case 'DELETE_BROKER':
      return { ...state }  
    case 'ASSOCIATE_BROKER':
      return { ...state } 
    case 'ADD_CONTACT':
      return { ...state }  
    case 'CLEAR_DATA':
      return { ...state, policies: [], limitsCount: { sombrero: [], pottencial: []} }      
    default:
      return state
  }
}
export default LeadsReducer
