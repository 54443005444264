// ** Initial State
const initialState = {
  modalCreate: false
}

const CadastrosReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODAL_CREATE':
      return { ...state, modalCreate: action.payload}
    default:
      return state
  }
}

export default CadastrosReducer