import mock from '../mock'

const userData = JSON.parse(localStorage.getItem('userData'))
const isAdmin = userData?.profile === 'admin'
const isOperational = userData?.profile === 'operacional'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 'leads',
        target: 'leads',
        isBookmarked: true,
        title: 'Leads',
        icon: 'TrendingUp',
        link: '/leads'
      },
      {
        id: 'clients',
        target: 'clients',
        isBookmarked: true,
        title: 'Clientes',
        icon: 'Users',
        link: '/clients'
      }
    ]
  }
]

if (isAdmin || isOperational) {
  searchArr[0].data.push({
    id: 'dashboard',
    target: 'dashboard',
    isBookmarked: true,
    title: 'Dashboard',
    icon: 'BarChart2',
    link: '/dashboard'
  },
  {
    id: 'demandas',
    target: 'demandas',
    isBookmarked: true,
    title: 'Demandas',
    icon: 'MdOutlineViewKanban',
    link: '/demandas'
  },
  {
    id: 'cadastros',
    target: 'cadastros',
    isBookmarked: false,
    title: 'Cadastros',
    icon: 'PlusSquare',
    link: '/cadastros'
  })
}

if (isAdmin) {
  searchArr[0].data.push(
    {
      id: 'financeiro',
      target: 'financeiro',
      isBookmarked: true,
      title: 'Financeiro',
      icon: 'DollarSign',
      link: '/financeiro/parcelas'
    }
  )
}

// GET Search Data
mock.onGet('/api/main-search/data').reply(config => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(config => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
