// ** Initial State
const initialState = {
  dashboardLeads: null,
  dashboardProduction: null
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DASHBOARD_LEADS_DATA':
      return { ...state, dashboardLeads: action.dashboardLeads }
    case 'DASHBOARD_PRODUCTION_DATA':
      return { ...state, dashboardProduction: action.dashboardProduction }
    default:
      return state
  }
}

export default DashboardReducer