// ** Initial State
const initialState = {
  installments: {
    data: [],
    count: 0,
    offset: 0
  },
  page: 1,
  count: 0,
  paidCards: {},
  currentCardPaid: null,
  sendFileResponse: null,
  receiptsData: {
    data: [],
    error: ''
  },
  commissionStatements: null,
  provisionReports: null
}

const FinanceReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_INSTALLMENTS':
      return {...state, installments: action.installments}  
    case 'GET_PAYMENTS':
      return {...state, payments: action.payments}  
    case 'SEND_FILE':
      return {...state, sendFileResponse: action.response}  
    case 'SET_CARD_PAID':
      const { installmentId } = action.payload
      return {
        ...state,
        paidCards: {
          ...state.paidCards,
          [installmentId]: true // Marca o card como pago
        }
      }
    case 'SET_CURRENT_CARD_PAID':
      return { ...state, currentCardPaid: action.payload }  
    case 'GET_COMMISSION_RECEIPT':
      return { ...state, receiptsData: action.receiptsData}  
    case 'GET_COMMISSION_STATEMENTS':
      return { ...state, commissionStatements: action.data}  
    case 'GET_PROVISION_REPORTS':
      return { ...state, provisionReports: action.data } 
    default:
      return state
  }
}

export default FinanceReducer
