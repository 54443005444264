// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { toast } from 'react-toastify'
import axios from 'axios'

const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data,
      config,
      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
    })
    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data))
    localStorage.setItem('userBrokers', JSON.stringify(data.brokers))
    localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.accessToken))
    localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(data.refreshToken))
    location.reload()
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem(config.storageTokenKeyName)
    localStorage.removeItem(config.storageRefreshTokenKeyName)
  }
}

export const loginError = (payload) => {
  return dispatch => {
    dispatch({
      type: 'LOGIN_ERROR',
      payload
    })
  }
}

export const forgotPasswordMail = (params) => {
  const { email, setResponse, setLoading } = params
  
  if (setLoading) setLoading(true)

  return async dispach => {
    try {
      const config = {
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}forgotPasswordMail`,
        headers: {
          'Content-Type': 'application/json',
          token: `Bearer ${localStorage.getItem('token')}`
        },
        data: { email }
      }
  
      await axios(config)
  
      toast.success('E-mail enviado!')

      if (setLoading) setLoading(false)
      if (setResponse) {
        setResponse(<span>Um link de recuperação foi enviado para <strong><u>{email}</u></strong>. Por favor, confira sua caixa de entrada. Caso não o tenha recebido, confira se o e-mail digitado está correto e tente novamente.</span>)
      }

    } catch (error) {
      console.log("🚀 ~ forgotPasswordMail ~ error:", error)
      toast.error('Erro ao enviar link de redefinição para o e-mail informado.')
      if (setLoading) setLoading(false)
    }
  }
}

export const resetPassword = (params) => {
  const { password, token, setResponse, setLoading } = params
  
  if (setLoading) setLoading(true)

  return async dispach => {
    try {
      const config = {
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}resetPassword/?token=${token}`,
        headers: {
          'Content-Type': 'application/json',
          token: `Bearer ${localStorage.getItem('token')}`
        },
        data: { password }
      }
  
      await axios(config)
  
      toast.success('Senha redefinida com sucesso!')
  
      if (setLoading) setLoading(false)
      if (setResponse) {
        setResponse({ color: 'success', message: 'Senha redefinida com sucesso. Retorne ao login para acessar o portal com as novas credenciais.', code: 200 })
      }

    } catch (error) {
      console.log("🚀 ~ resetPassword ~ error:", error)
      toast.error('Erro ao processar a solicitação para recuperação de senha. Tente novamente.')
      if (setLoading) setLoading(false)
    }
  }
}

